import React from 'react';
import './App.css';
import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {useFormik} from 'formik';
import DetailsForm from "./components/DetailsForm";
import RoomNumberForm from "./components/RoomNumberForm";
import HouseTypeForm from "./components/HouseTypeForm";
import MapLocationForm from "./components/MapLocationForm";
import axios from "axios";
import ThankYouPage from './components/ThankYouPage';


export interface RentEvaluationFormType {
    location?: string,
    postcode?: string,
    email?: string,
    firstname?: string,
    lastname?: string,
    phoneNumber?: string
    houseType?:string
    rooms?:number
}


function App() {
    const steps = [1, 2, 3, 4, 5];
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const formik = useFormik<RentEvaluationFormType>({
        initialValues: {
            location:'',
            postcode: undefined,
            firstname: undefined,
            lastname:undefined,
            email: undefined,
            phoneNumber:undefined,
            houseType:undefined,
            rooms: undefined,
        },
        onSubmit: values => {
            const entrypoint = process.env.REACT_APP_BACKEND_ENTRYPONT +"/estimation"
            axios.post(entrypoint,values).then(()=>handleNext())
        },
        onReset: values => {
            handleReset()
        }
    });


    return (
        <Box sx={{px: 1, display: "flex", justifyContent: "center"}}>
            <Box sx={{maxWidth:900, width:"100%"}}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{my:3}} variant="determinate" value={(activeStep+1)/steps.length*100}></LinearProgress>
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <React.Fragment>
                                    {getStep(activeStep,formik)}
                                <Box sx={{ display: 'flex', justifyContent:"center"}}>
                                    {activeStep!== 0 && <Button
                                        variant="contained"
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{mr: 1, width:100}}
                                    >
                                        Back
                                    </Button>}
                                    {getNextButton(activeStep, formik, handleNext)}
                                </Box>
                            </React.Fragment>
                        </div>
                    </form>
                </Box>
            </Box>
        </Box>


    );
}

function getStep(active:number,formik:any){
    if(active===0){
        return <Box sx={{minHeight:300, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <h1>Find Out How Much Rent Your Property Can Achieve</h1>
            <MapLocationForm formik={formik}></MapLocationForm>
        </Box>
    }
    if(active === 1){
        return <HouseTypeForm formik={formik}></HouseTypeForm>
    }
    if(active ===2 ){
        return <RoomNumberForm formik={formik}></RoomNumberForm>
    }
    if(active ===3 ){
        return <DetailsForm formik={formik}></DetailsForm>
    }
    if(active === 4){
        return <ThankYouPage formik={formik}></ThankYouPage>
    }
}

function getNextButton(active:number,formik:any, handleNext: () => void){
    if(active=== 0){
        return <Button type="button" disabled={formik.values.postcode === undefined} onClick={handleNext} sx={{ mr: 1, width:100 }} variant="contained">
            Next
        </Button>
    }
    if(active === 1){
        return <Button type="button" disabled={formik.values.houseType === undefined} onClick={handleNext} sx={{ mr: 1, width:100 }} variant="contained">
            Next
        </Button>
    }
    if(active === 2){
        return <Button id="roomsButton" type="button" disabled={formik.values.rooms === undefined} onClick={handleNext} sx={{ mr: 1, width:100 }} variant="contained">
            Next
        </Button>
    }
    if(active=== 3){
        return <Button id="submitButton" disabled={formik.isValid === undefined} onClick={formik.submitForm} sx={{ mr: 1, width:100 }} variant="contained">
            Submit
        </Button>
    }

    return <></>

}

export default App;
