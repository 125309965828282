import React from "react";
import GoogleInput from "./GoogleInput";

interface MapLocationFormInterface{
    formik:any
}

export default function MapLocationForm({formik}:MapLocationFormInterface){
    return <GoogleInput  formik={formik}></GoogleInput>
}
