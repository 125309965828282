import React from "react";
import {Box, Grid, TextField} from "@mui/material";
interface DetailsFormInterface{
    formik:any
}

export default function DetailsForm({formik}:DetailsFormInterface){
    return <Box sx={{minHeight:300, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h1>Your Details For A Copy Of The Report?</h1>
        <Grid spacing={2} container>
            <Grid xs={6} item>
                <TextField sx={{width:"100%"}} id="firstname" name="firstname" label="First Name"   onChange={formik.handleChange} value={formik.values.firstname} />
            </Grid>
            <Grid xs={6} item>
                <TextField sx={{width:"100%"}} id="lastname" name="lastname" label="LastName"   onChange={formik.handleChange} value={formik.values.lastname} />
            </Grid>
            <Grid xs={6} item>
                <TextField sx={{width:"100%"}} id="email" name="email" label="Email"   onChange={formik.handleChange} value={formik.values.email} />
            </Grid>
            <Grid xs={6} item>
                <TextField sx={{width:"100%"}} id="phoneNumber" name="phoneNumber" label="Phone number"   onChange={formik.handleChange} value={formik.values.phoneNumber} />
            </Grid>

        </Grid>
    </Box>
}
