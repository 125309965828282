import {Box, Button, Grid} from "@mui/material";
import React from "react";

interface RoomNumberFormInterface{
    formik:any
}

const numbers =  Array.from({length: 9}, (_, index) => index + 1);

export default function RoomNumberForm({formik}:RoomNumberFormInterface){
    return <Box sx={{minHeight: 300, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <h1>How many bedrooms are there?</h1>
        <Grid container sx={{my:3, mx:3}}>
            {numbers.map(num => <Grid xs={4} md={2} item sx={{px:1, py:1}}><Button sx={{height:"100%", width:"100%", fontSize:"2rem", px:2, py:2}} onClick={()=>{formik.setFieldValue("rooms",num)}} variant={formik.values.rooms === num ? "contained": "outlined"}>{num}</Button></Grid>)}
        </Grid>
    </Box>
}
