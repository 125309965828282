import React from "react";
import {Box, Button} from "@mui/material";
interface ThankYouPageInterface{
    formik:any
}
export default function ThankYouPage({formik}:ThankYouPageInterface){
    return <Box sx={{minHeight:300, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <div>
            <h1>Thank you for your submission!</h1>
            <p> Dear {formik.values.firstname} {formik.values.lastname},</p>
            <p>You are about to receive an email with the evaluation of your property at the email address you provided.</p>
            <p>If you want to evaluate another property, please press the reset button.</p>
        </div>
        <div>
            <Button variant="contained" onClick={()=>{formik.resetForm()}}>Reset</Button>
        </div>


</Box>
}
