import {Box, Button} from "@mui/material";
import React from "react";

interface HouseTypeFormInterface{
    formik:any
}


export default function HouseTypeForm({formik}:HouseTypeFormInterface){
    return <Box sx={{height:300,display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
        <h1>What type of property do you have?</h1>
        <div>
            <Button size="large" sx={{width:200, height:80, mx:1}} onClick={()=>{formik.setFieldValue("houseType","house")}} variant={formik.values.houseType === "house" ? "contained": "outlined"}>House</Button>
            <Button size="large" sx={{width:200, height:80, mx:1}} onClick={()=>{formik.setFieldValue("houseType","flat")}} variant={formik.values.houseType === "flat" ? "contained": "outlined"}>Flat</Button>
        </div>
    </Box>
}
