import GooglePlacesAutocomplete, {geocodeByPlaceId} from "react-google-places-autocomplete";
import React, {useState} from 'react';
import {Grid} from "@mui/material";


const options = {
    componentRestrictions: { country: "uk" },
    fields: ["geometry"],
    strictBounds: false,
    types: [],
};

interface GoogleInputInterface{
    formik:any

}

export default function GoogleInput({formik}:GoogleInputInterface){
    const [value,setValue] = useState<string|undefined>();

    const onChange = (result:any) => {
        geocodeByPlaceId(result.value.place_id)
            .then(results => {
                const firstResult = results[0]
                const location = firstResult["geometry"]["location"]
                const addressComponent = firstResult["address_components"].find(addressComponent => addressComponent.types.includes("postal_code"))
                formik.setFieldValue("postcode",(addressComponent?.long_name ?? null));

                const streetNumber= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("street_number"))

                const adminArea1= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("administrative_area_level_1"))

                const adminArea2= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("administrative_area_level_2"))

                const country= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("country"))

                const postalTown= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("locality"))
                /*setAddressPostalTown(postalTown?.long_name ?? null);
                setAddress(result.label)
                setLongitude(location.lng().toString())
                setLatitude(location.lat().toString())*/
            })
            .catch(error => console.error(error));
    }

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <GooglePlacesAutocomplete autocompletionRequest={options} apiKey={"AIzaSyChBxMuwMhMtZvgZSzWfGN70dK2v0HP5K0"} selectProps={{onInputChange:setValue, onChange: onChange,
                styles: {
                    input: (provided) => ({
                        ...provided,
                        height: 50,
                    }),
                    option: (provided) => ({
                        ...provided,
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                    }),
                    control: (provided) => ({
                        ...provided,
                        borderColor: "#6EC1E4"
                    })

                },
                theme: (theme => (
                    {
                        ...theme, colors:{...theme.colors, primary:"#6EC1E4"}
                    }
                ) )
            }}/>
        </Grid>
    </Grid>
}
